import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Partenaires = () => {
  useEffect(() => {
    // Aucun besoin d'initialiser le carrousel ici
  }, []);

  const slickSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div>
      <div className="section5" id="section_partenaires">
      <div className="text-center pb-5"><span className='nospartenaires'>Ils nous font confiance</span></div>
        <div className="container">
          <div className="row d-flex">
            <Slider {...slickSettings} className="customer-logos">
              <div className="slider">
                <img src="/img/logofede.svg" alt="Fede" width="90" height="90" />
              </div>
              <div className="slide">
                <img src="/img/pmn.svg" alt="Pmn" width="90" height="90" />
              </div>
              <div className="slide">
                <img src="/img/fitec.svg" alt="Fitec" width="90" height="90" />
              </div>
              <div className="slide">
                <img src="/img/certyup.svg" alt="Certyjob" width="90" height="90" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partenaires;
