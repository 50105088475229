import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const Barhaut = () => {
  const navbarRef = useRef(null);

  const location = useLocation();

  const isHomeActive = location.pathname.startsWith("/ ");
  const isFormationActive = location.pathname.startsWith("/formations");
  const isCerticationActive = location.pathname.startsWith("/certifications");
  const isPartenaireActive = location.pathname.startsWith("/partenaires");
//   const isCerticationActive = location.pathname.startsWith("/certification");



  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className="section1 sticky-top">
      <div className="container">
        <Navbar
          bg=""
          variant="dark"
          sticky="top"
          expand="lg"
          collapseOnSelect
          ref={navbarRef}
        >
          {/* Logo à gauche */}
          <Navbar.Brand className="navbarbrand">
            <a href="/">
              <img
                src="/img/logoneoleanacademy.svg"
                width="150px"
                height="90px"
              />
            </a>
          </Navbar.Brand>

          {/* Menu à droite */}
          <Navbar.Toggle className="coloring" />
          <Navbar.Collapse className="justify-content-end">
            <div>
              <Nav className="navbarnav">
                <Link
                  to="/"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={
                    isHomeActive
                      ? "active"
                      : "focus me-3 nav-link"
                  }
                >
                  QUI SOMMES-NOUS ?
                </Link>

                <Link
                  to="/formations"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={
                    isFormationActive
                      ? "active"
                      : "focus me-3 nav-link"
                  }
                >
                  NOS FORMATIONS
                </Link>

                <Link
                  to="/certifications"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={
                    isCerticationActive
                      ? "active"
                      : "focus me-3 nav-link"
                  }
                >
                  NOS CERTIFICATIONS
                </Link>

                <Link
                  to="/partenaires"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={
                    isPartenaireActive
                      ? "active"
                      : "focus me-3 nav-link"
                  }
                >
                  NOS PARTENAIRES
                </Link>

                <ScrollLink
                  to="section_contacts"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="focus me-3 nav-link"
                >
                  CONTACTS
                </ScrollLink>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Barhaut;
