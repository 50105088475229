import React, { useEffect } from 'react';
import Barhaut from './Barhaut';
import Footer from './Footer';
import { useParams } from 'react-router-dom';
import WhatsAppWidget from './WhatsAppWidget';

import Accordions from './Accordion';

const CertificationDetailsPlus = (props) => {
    const { id } = useParams();
  const { listecertifications } = props;
  const certificationId = parseInt(id);
  const certification = listecertifications.find((certification) => certification.id === certificationId);

  // Rediriger vers le haut de la page
  useEffect(() => {
    if (certification) {
      window.scrollTo(0, document.getElementById("top").offsetTop);
    }
  }, [certification]);

  if (!certification) {
    return <div>Certification non trouvée</div>;
  }
    
    return (
        <div className="sectionformationdetailsplus">
            <Barhaut/>
            <div id="top"></div>
            <div className='pb-4'>
            <div className="entete" id="accueil">
                <div className="container">
                <h2 className="text2">{certification.certificat}</h2>
              
            <br />
              <h6 className="text1">{certification.accroche1}</h6>
                <h6 className="text1">{certification.accroche2}</h6>
                
                {/* <div><img className='decor'  alt="" /></div> */}
               
                </div>
            </div>
            </div>
            <div className="container">
                <div className="row d-flex justify-content-around pt-5">
                    <div className="col-lg-7">
                    <h2 className='titre'>{certification.niveau}</h2>
                    <div className='description py-4'>{certification.texte}</div>
                    <h2 className='titre pb-4'>Programme</h2>
                    <div className='module'>
                    <ol style={{ textAlign: 'justify' }}>
                    {
                                    certification.programmes.map((programme, index) => (
                                        <Accordions title={programme.title} content={programme.content}/>
                                       
                                ))
                            }
                        
                        </ol>
                    </div>
                    <div className='blocresumecertif my-5'>
                        <span>{certification.resume}</span>
                    </div>

                    </div>
                    <div className="col-lg-4">
                        <div className='blocprogramme px-3 py-4'>
                            <h2 className='programme'>Objectifs :</h2>
                            <ol className='obejectifcerti'>
                            {certification.objectifs.map((objectif, index) => (
                            <li key={index} style={{ whiteSpace: 'pre-wrap' }} className='listeobjectif'>
                            {index === 0 ? (
                                <span className="first-objectif-style" dangerouslySetInnerHTML={{ __html: objectif }} />
                            ) : index === 1 ? (
                                <span className="second-objectif-style" dangerouslySetInnerHTML={{ __html: objectif }} />
                            ) :index ===2 ? (
                                <span className="second-objectif-style" dangerouslySetInnerHTML={{ __html: objectif }} />
                            ) :index ===3 ? (
                                <span className="second-objectif-style" dangerouslySetInnerHTML={{ __html: objectif }} />
                            ): (
                                objectif
                            )}
                            </li>
                        ))}
                                    </ol>
                        </div>
                       
                    </div>
                </div>
                <div className="row d-flex justify-content-around align-items-center py-5">
                    {/* <div className="col-lg-7">
                    <div className='blocresume'>
                        <span>{certification.resume}</span>
                    </div>
                    </div> */}
                    {/* <div className="col-lg-4">
                    <div className="blocdebouche d-flex justify-content-center align-items-center">
                            <img src="/icones/iconedebouche.svg" alt="" className='imagedebouche'/>
                            <div>
                            <ul>
                                {selectedCertification.debouches.map((debouche, index) => (
                                    debouche && <li key={index}>{debouche}</li>
                                ))}
                                </ul>

                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className="container mb-5">
                <div className="row py-5 sectiondownload1 d-flex align-items-center justify-content-center">
                    <div className="col-lg-10">
                    <div className='sectiondownload2 py-5'>
                    <div className='bloc'>
                    <span className='accroche d-block text-center mx-auto'>
                    ddfff
                    </span>
                        <h2 className='domaine text-center'>ssss</h2>
                    </div>
                        <div className="text-center">
                        <button className='btntelecharger mt-3'>Télécharger la plaquette</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div> */}

            </div>
            <Footer/>
            <WhatsAppWidget/>
        </div>
    );
};

export default CertificationDetailsPlus;
