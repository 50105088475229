import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

const Inscription = () => {
   
    return (
       <div className="col-lg-8 mt-4">
            <div className="cardinscription position-relative">
                <div className="icon-container">
                    <img src="./icones/fouvert.svg" alt="" srcSet="" className="image" />
                </div>
                <div className="card-body">
               
                    <h2 className='inscription pb-2'>Les inscriptions pour la rentrée de <span className='periode'>SEPTEMBRE 2024</span> sont ouvertes.</h2>
                   
                    <ScrollLink to="section_formations_ouvertes" className="formations_ouvertes d-flex justify-content-center align-items-center">
                      <button className='btnformationsouvertes'>Voir les formations ouvertes</button>
                      </ScrollLink>
                  
                </div>
            </div>
       </div>
    );
};

export default Inscription;