import React, { useRef } from 'react';
import Barhaut from '../components/accueil/Barhaut';
import Bandeau1 from '../components/accueil/Bandeau1';
import Partenaires from '../components/accueil/Partenaires';
import Footer from '../components/accueil/Footer';
import Bandeau2 from '../components/accueil/Bandeau2';
import Bandeau3 from '../components/accueil/Bandeau3';
import Bandeau4 from '../components/accueil/Bandeau4';
import Certifications from '../components/accueil/Certifications';
import Objectifs from '../components/accueil/Objectifs';
import Bandeau22 from '../components/accueil/Bandeau22';
import Temoignages from '../components/accueil/Temoignages';
import WhatsAppWidget from '../components/accueil/WhatsAppWidget';
const categories = require('../configs/formations.json')
const certifications = require('../configs/certifications.json')

const Accueil = () => {
  
    return (
        <div className="accueil_section">
            <Barhaut/>
            <Bandeau1/>
            <Bandeau2/>
            <Bandeau22/>
            <Bandeau3/>
            <Bandeau4 categoriesData={categories.categoriesformations}/>
            <Certifications certificationsData={certifications.categoriescertification}/>
            <Objectifs/>
            <Partenaires/>
            <Temoignages/>
            <Footer/>
            <WhatsAppWidget/>
        </div>
    );
};

export default Accueil;