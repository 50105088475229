import React from 'react';
import Certifications from '../components/accueil/Certifications';
import Footer from '../components/accueil/Footer';
import WhatsAppWidget from '../components/accueil/WhatsAppWidget';
import Barhaut from '../components/accueil/Barhaut';
const certifications = require('../configs/certifications.json')

const CertificationsPage = () => {
    return (
        <div className='certif_page'>
             <Barhaut/>
             <div className='pb-4'>
            <div className="entetepage" id="accueil">
                <div className="container">
                <h2 className="text2">Nos Certifications internationales</h2>
                {/* <br />
                <h6 className="text1"></h6> */}
                </div>
            </div>
            </div>
             <Certifications certificationsData={certifications.categoriescertification}/>
             <div className='espacement'></div>
             <Footer/>
            <WhatsAppWidget/>
        </div>
    );
};

export default CertificationsPage;