import React from 'react';
import Barhaut from '../components/accueil/Barhaut';
import WhatsAppWidget from '../components/accueil/WhatsAppWidget';
import Footer from '../components/accueil/Footer';
import Partenaires from '../components/accueil/Partenaires';
import Temoignages from '../components/accueil/Temoignages';

const PartenairesPage = () => {
    return (
        <div>
            <Barhaut/>
            <div className='pb-4'>
            <div className="entetepage" id="accueil">
                <div className="container">
                <h2 className="text2">Nos partenaires</h2>
                {/* <br />
                <h6 className="text1"></h6> */}
                </div>
            </div>
            </div>
            <div className='mt-5'></div>
            <Partenaires/>
            <Temoignages/>
             <Footer/>
            <WhatsAppWidget/>
        </div>
    );
};

export default PartenairesPage;