import logo from './logo.svg';
import './styles/index.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Accueil from './pages/Accueil';
import FormationDetails from './components/accueil/FormationDetails';
import FormationDetailsPlus from './components/accueil/FormationDetailsPlus';
import CertificationDetailsPlus from './components/accueil/CertificationDetailsPlus';
import Formations from './pages/Formations';
import CertificationsPage from './pages/CertificationsPage';
import PartenairesPage from './pages/PartenairesPage';
const fichierformationsjson = require('./configs/formations.json');
const fichiercertificationsjson = require('./configs/certifications.json')

function App() {
  return (
    <BrowserRouter>
    <Routes>
      
      <Route path="/" element={<Accueil />} />
      {/* <Route path="/formations/:categorieId" component={FormationDetails} /> */}
      <Route path="/formations/:titre/:id" element={<FormationDetails listeformations={fichierformationsjson.categoriesformations}/>} />
      <Route path="/formations/:titre/:id/:formation/:formationId" element={<FormationDetailsPlus listeformations={fichierformationsjson.categoriesformations} />} />
      {/* <Route path="/certification/:id/:certification/:certificationId" element={<CertificationDetailsPlus listecertifications={fichiercertificationsjson.categoriescertification} />} /> */}
      <Route path="/certifications/:certificat/:id" element={<CertificationDetailsPlus listecertifications={fichiercertificationsjson.categoriescertification}/>} />
      <Route path="/formations" element={<Formations />} />
      <Route path="/certifications" element={<CertificationsPage />} />
      <Route path="/partenaires" element={<PartenairesPage />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
