import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import categoriesData from "../../configs/formations.json";
import Barhaut from "./Barhaut";
import { Card, CardContent } from "@mui/material";
import Footer from "./Footer";
import WhatsAppWidget from "./WhatsAppWidget";

const FormationDetails = (props) => {
  const { id } = useParams();
  const { listeformations } = props;
  const formationId = parseInt(id);
  const formation = listeformations.find(
    (formation) => formation.id === formationId
  );

  // Rediriger vers le haut de la page
  useEffect(() => {
    if (formation) {
      window.scrollTo(0, document.getElementById("top").offsetTop);
    }
  }, [formation]);

  if (!formation) {
    return <div>Formation non trouvée</div>;
  }

  return (
    <div className="sectionformationdetails">
      <div id="top"></div>
      <Barhaut />

      <div className="pb-4">
        <div className="entete" id="accueil">
          <div className="container">
            <h2 className="text2">{formation.titre}</h2>
            <br />
            <h6 className="text1">{formation.texte}</h6>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex py-5 mb-5 justify-content-around">
          {formation.formations.map((item) => (
            <div key={item.profil} className="col-lg-5 mb-5">
              <div className="card">
                <div className="card-inner">
                  <div className="card-front">
                    <div className="card-content text-center">
                      <img src={item.icone} alt="" />
                      <div className="niveau1 pt-3">{item.niveau}</div>
                      <div className="profil1">{item.profil}</div>
                    </div>
                  </div>
                  <div className="card-back">
                    <div
                      className="duree d-none d-md-block pt-5"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 28,
                        backgroundColor: "#F3034D",
                        color: "white",
                        padding: "4px 8px",
                        fontSize: "11px",
                        height: "90px",
                      }} 
                    >
                      <div className="en">en</div>
                      <span className="styleduree">{item.option}</span>
                    </div>
                    <div
                      className="duree d-md-none d-block pt-3"
                      style={{
                        position: "absolute",
                        top: -5,
                        right: 5,
                        backgroundColor: "#F3034D",
                        color: "white",
                        padding: "4px 8px",
                        fontSize: "11px",
                        height: "50px",
                        borderRadius: "0px 0px 0px 0px",
                      }}
                    >
                      <div className="en2"></div>
                      <span className="styleduree2">{item.option}</span>
                    </div>
                    <div>
                      <div className="niveau">{item.niveau}</div>
                      <div className="profil py-1" >{item.profil}</div>
                      <p className="descriptioncard py-1">{item.description}</p>

                      <div>
                        <Link
                          to={`/formations/${formation.titre}/${formation.id}/${item.profil}/${item.id}`.replace(
                            " ",
                            "-"
                          )}
                          className="no-underline"
                        >
                          <button className="ensavoirplus">
                            En savoir plus
                          </button>
                        </Link>
                      </div>
                      <img
                        className="d-none d-md-block"
                        src={item.decor}
                        alt=""
                        style={{
                          position: "absolute",
                          bottom: 5,
                          right: 20,
                          width: "140px",
                          height: "125px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <WhatsAppWidget />
    </div>
  );
};

export default FormationDetails;
