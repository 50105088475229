import React from 'react';

const Bandeau2 = () => {
    return (
        <div className="section3 d-none d-lg-block" id="section_qui_sommes_nous">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-start">
            <div className='bloc1'>
            <span className='quisommesnous'>Qui sommes-nous ?</span>
            <div className='py-4'>
              <h2 className='titre1'>Neolean Academy <br />est bien plus qu'une école:</h2>
            </div>
            <div>
              <ul className="list-unstyled">
                <li className="d-flex align-items-center mb-3">
                  <img src="./icones/icon1.svg" alt="icone1" width="43px" height="42px" />
                  <span className="ms-2 texte">
                    C'est un laboratoire d'idées qui façonne l'avenir
                  </span>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <img src="./icones/icon2.svg" alt="icone2" width="43px" height="42px" />
                  <span className="ms-2 texte">
                    Ce sont des parcours de formation diplômants
                  </span>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <img src="./icones/icon3.svg" alt="icone3" width="43px" height="42px" />
                  <span className="ms-2 texte">
                    Ce sont des certifications internationales
                  </span>
                </li>
              </ul>
            </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="imagep">
              <img src="./img/imagepp.svg" alt="imagepresentation" />
            
            </div>
          </div>
        </div>
        
      </div>
    </div>
    
    );
};

export default Bandeau2;