import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';

export default function Accordions({title = "",content=[]}) {
  return (
    <div>
      <Accordion className='mb-2' style={{border:"1px solid #1D4E9C", borderRadius:"20px"}}>
        <AccordionSummary
        expandIcon={<ArrowDropDownCircleOutlined  style={{color:"#1D4E9C"}}/>}
     
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography sx={{fontFamily:'Montserrat Bold', padding:"1rem 1rem 0.6rem 1rem",fontSize:"18px",color:"#f05b06",textAlign:"start"}}>{title}</Typography>
          
        </AccordionSummary>
        <div style={{borderBottom:"1px solid #1D4E9C"}} className='mx-4 mb-4'></div>
        <AccordionDetails>
            
          <Typography>
            
           <ul>
           
               {content.map((item, index) => (
                item.sub_content ?
                    <>
                    <li className='listeprogrammea' key={index}>{item.sub_content.title} </li>
                    <ul style={{paddingLeft:"0"}}>
                        {
                            item.sub_content?.content?.map((ite, i) =>(
                                <li className='listeprogrammec' key={i}>{ite}</li>
                            ))
                        }

                    </ul>
                    </>
                :
                    <li className='listeprogrammec' key={index}>{item} </li>

                ))}
           </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
     
    </div>
  );
}