import React from 'react';
import Barhaut from '../components/accueil/Barhaut';
import Bandeau4 from '../components/accueil/Bandeau4';
import Footer from '../components/accueil/Footer';
import WhatsAppWidget from '../components/accueil/WhatsAppWidget';
const categories = require('../configs/formations.json')

const Formations = () => {
    return (
        <div>
             <Barhaut/> 
             <div className='pb-4'>
            <div className="entetepage" id="accueil">
                <div className="container">
                <h2 className="text2">Nos formations</h2>
                {/* <br />
                <h6 className="text1"></h6> */}
                </div>
            </div>
            </div>
             <Bandeau4 categoriesData={categories.categoriesformations}/>
             <Footer/>
            <WhatsAppWidget/>
        </div>
    );
};

export default Formations;