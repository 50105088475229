import React from 'react';
import Slider from 'react-slick';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Temoignages = () => {
  const temoignagesData = [
    {
      nom: 'Amadou NDIAYE',
      domaine: 'Bachelor Full Stack',
      texte: "Je voulais trouver une formation adaptée à la fois aux besoins du marché et à mes ambitions. J’ai trouvé ce qu’il me fallait en m’inscrivant chez Neolean Academy. J’ai suivi un Bachelor Developpeur Full Stack. Ce que j’ai le plus apprécié dans ma formation, c’était l’expertise des formateurs et la partie pratique très importante dans la formation et qui m’a permis de mieux comprendre et de bien me préparer au monde de l’entreprise.",
    },
    {
        nom: 'Fatou NDAO',
        domaine: 'Certification professionnelle “Gestion de la relation client"',
        texte: "Je m’appelle Fatou Ndao, j’ai 25 ans et je suis chargée de clientéle chez Orange. J’ai souhaité me former et monter en compétence dans mon métier. Ce que j’ai appris dans mon métier je l’ai appris sur le terrain et je voulais avoir plus d’outils et être plus structurée pour mieux évoluer dans mon environnement professionnel. J’ai choisi de me former et de passer la certification “Gestion de la relation client” que propose Neolean Academy. Je me suis tout de suite retrouvée dans cette formation et j’ai beaucoup appris. J’ai pu profiter du retour d’expériences des formateurs tous professionnels dans le domaine. Ils m’ont donner plein d’astuces et techniques pour mieux communiquer et gérer mes clients notemment en situation conflictuelle. Merci encore à toute l’équipe!",
      },
    // Ajoutez d'autres témoignages au besoin
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Activer le défilement automatique
    autoplaySpeed: 5000, 
  };

  return (
    <div>
      <div className="nostemoignages" id="section_4">
        <div className="text-center pb-5">
          <span className='temoignages'>Nos témoignages</span><br />
          <span className='h2'>Que disent nos étudiants ?</span>
        </div>
        <div className="container">
          <div className="bgtemoignages row justify-content-around py-5">
            
                  <Card className='cardstyle mb-5'>
                    <CardContent className='py-5'>
                    <Slider {...settings}>
              {temoignagesData.map((temoignage, index) => (
                <div key={index}>
                      <div className='text-center nom'>{temoignage.nom}</div>
                      <div className='text-center domaine'>{temoignage.domaine}</div>
                      <div className='texte-container' style={{ maxWidth: '800px', margin: 'auto' }}>
                        <div className='text-center py-4 texte'>
                          {temoignage.texte}
                        </div>
                      </div>
                      </div>
              ))}
            </Slider>
                    </CardContent>
                  </Card>
              
          </div>
        </div>
      </div>
    </div>
  );
};

export default Temoignages;
