import React from "react";
import { Link } from "react-router-dom";

const Bandeau4 = (props) => {
  const { categoriesData } = props;

  return (
    <div>
      <div className="section4" id="section_formations">
        <div className="text-center py-5">
          <span className="nosformations">Nos diplômes</span>
          <br />
          <span className="h2">internationaux</span>
        </div>
        <div className="container">
          <div className="row d-flex align-items-center justify-content-around">
            {categoriesData.map((categorie) => (
              <div key={categorie.id} className="col-lg-6">
                <Link
                  to={`/formations/${categorie.titre}/${categorie.id}`.replace(
                    " ",
                    "_"
                  )}
                  className="lien"
                >
                  <div className="card mb-3 categorie">
                    <div className="card-body d-flex align-items-center justify-content-between">
                      <h5 className="card-title titre">{categorie.titre}</h5>
                      <img
                        className="fleche"
                        src="./icones/fleche.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </div>
            ))}

            {/* <div className="col-lg-6">
              <div className="card mb-3 categorie">
                <div className="card-body d-flex align-items-center justify-content-between">
                  <div className="neoaca d-flex align-items-center justify-content-center titre">
                    Formation à venir !
                  </div>
                  <h5 className="card-title titre">Nos Masters internationaux</h5>
                  <img src="./icones/fleche.svg" alt="" />
                </div>
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="card mb-3 categorie">
                <div className="card-body d-flex align-items-center justify-content-between">
                  <div className="neoaca d-flex align-items-center justify-content-center titre">
                    Formation à venir !
                  </div>
                  <h5 className="card-title titre">Nos MBA internationaux</h5>
                  <img src="./icones/fleche.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-3 categorie">
                <div className="card-body d-flex align-items-center justify-content-between">
                  <div className="neoaca d-flex align-items-center justify-content-center titre">
                    Formation à venir !
                  </div>
                  <h5 className="card-title titre">Nos DBA internationaux</h5>
                  <img src="./icones/fleche.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bandeau4;
