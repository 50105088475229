import React from 'react';
import Slider from 'react-slick';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

// // Importez votre fichier JSON
// import certificationsData from '../../configs/certifications.json';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const Certifications = (props) => {
  const { certificationsData } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Activer le défilement automatique
    autoplaySpeed: 1500, // Durée entre chaque diapositive en millisecondes (ici, 3 secondes)
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="certification" id="section_certifications">
        <div className="text-center py-5">
          <span className='decouvrez'>Découvrez</span><br />
          <span className='h2'>Nos Certifications Professionnelles</span>
        </div>
        <div className="container">
          <Slider {...settings}>
            {certificationsData.map((certification) => (
              <div key={certification.id}>
                  <Link className="linkcertification" to={`https://www.certyup.jobs/formation/${certification.certificat}-${certification.id}`}>
                <Card sx={{ maxWidth: 370, margin: 'auto' }} className='mb-5 px-3' style={{ boxShadow: "none" }}>
                  <CardMedia
                    component="img"
                    height="194"
                    image={certification.image}
                    alt="Certification Image"
                    className='py-3'
                  />
                  <CardContent>
                    <div className='domaine'>{certification.domaine}</div>
                    <div className='certificat py-2'>{certification.certificat}</div>
                    <p className='texte' style={{ maxHeight: '80px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {certification.texte}
                      </p>
                    <div>
                      <button className='btnplus w-100 py-2'>En Savoir Plus</button>
                    </div>
                  </CardContent>
                </Card>
                </Link>

              </div>

            ))}
          </Slider>
          <div className="row pt-4 d-none d-lg-block">
          <div className='position-relative d-flex justify-content-end'>
           <a href='https://certyup.jobs/' target='_blank' rel='noopener noreferrer' className='btncertyup'>
            Découvrir sur CertyUp.jobs
          </a>
        </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Certifications;
