import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import emailjs from "emailjs-com";

const Footer = () => {
  const [formData, setFormData] = useState({
    nom: "",
    email: "",
    message: "",
  });
  // const [successMessage, setSuccessMessage] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Configuration des paramètres EmailJS
    const serviceId = "service_gujgui8";
    const templateId = "template_qvtf7oh";
    const userId = "R-P6mTAQFMARAvyr1";

    // Envoi de l'email via EmailJS
    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((response) => {
        setResponseMessage("Votre message a été envoyé avec succès !");
        setFormData({
          nom: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        setResponseMessage("Erreur lors de l'envoi du message.");
        console.error("Erreur lors de l'envoi de l'email :", error);
      });

    // const ff = new FormData();
    // ff.append('nom', formData.nom);
    // ff.append('email', formData.email);
    // ff.append('message', formData.message);

    // try {
    //   const response = await axios.post('http://backend.neoleanacademy.digital/index.php', ff);

    //   setSuccessMessage(response.data.success || 'Message envoyé avec succès');
    //   setErrorMessage(''); // Réinitialisez le message d'erreur
    //   setFormData({ nom: '', email: '', message: '' }); // Videz les champs du formulaire
    // } catch (error) {
    //   setErrorMessage(error.response?.data?.error || 'Une erreur s\'est produite lors de l\'envoi du message');
    //   setSuccessMessage(''); // Réinitialisez le message de succès
    // }
  };

  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => {
        setResponseMessage('');
      }, 2000); // 2 secondes

      return () => {
        clearTimeout(timer);
      };
    }
  }, [responseMessage]);

  return (
    <div>
      <div className="sectioncontact pb-5" id="section_contacts">
        <div className="container">
          <div className="row">
            <div className="col-md-6 py-5">
              <h2 className="contact pb-4">Contacte-nous</h2>
              <form onSubmit={handleSubmit} id="contact-form" method="post">
                <input
                  type="text"
                  required
                  name="nom"
                  className="form-control mb-3"
                  placeholder="Prénom Nom"
                  value={formData.nom}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  required
                  name="email"
                  className="form-control mb-3"
                  placeholder="E-mail"
                  value={formData.email}
                  onChange={handleChange}
                />
                <textarea
                  type="text"
                  required
                  name="message"
                  cols="30"
                  rows="10"
                  className="form-control mb-3"
                  placeholder="Message"
                  style={{ maxHeight: "150px" }}
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <div className="row d-flex align-items-center">
                  <div className="col">
                    <button type="submit" className="btn btnenvoyer">
                      Envoyer
                    </button>
                  </div>
                  <p className="text-center mt-4" style={{color:"white"}}>{responseMessage}</p>

                  <div
                    className="col"
                    id="response-message"
                    style={{ fontSize: "30px" }}
                  >
                    {/* <div className="success-message">{responseMessage}</div> */}
                    {/* Affichage du message de succès */}
                    {/* {successMessage && (
                      <div className="success-message">{successMessage}</div>
                    )} */}
                    {/* Affichage du message d'erreur */}
                    {/* {errorMessage && (
                      <div className="error-message">{errorMessage}</div>
                    )} */}
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <div className="localisation">
                <div className="contact-info shadow-lg">
                  <a href="" className="localisationstyle">
                    <h5 className="d-flex mb-2" style={{ lineHeight: "25px" }}>
                      {/* <div>
                      <FontAwesomeIcon icon={faLocationDot} className='contact-icon me-2'/>
                      </div> */}
                      <div className="siege">
                        Lot 419 mamelles en face du phare , Dakar Sénégal.
                      </div>
                    </h5>
                  </a>

                  <h5 className="mb-2">
                    <a href="tel:+221771715858" className="contactstyle">
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="contact-icon me-2"
                      />
                      +221 77 171 58 58
                    </a>
                  </h5>

                  <h5>
                    <a
                      href="mailto:academy@neoleanacademy.digital"
                      className="footer-link contactstyle"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="contact-icon me-2"
                      />
                      neolean.academy@neolean.digital
                    </a>
                  </h5>

                  <iframe
                    className="google-map mt-4"
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3265.542624989966!2d-17.50131666766959!3d14.723608944241157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDQzJzI1LjQiTiAxN8KwMjknNTYuMCJX!5e0!3m2!1sfr!2ssn!4v1719484203594!5m2!1sfr!2ssn"
                    width="100%"
                    height="300"
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Map"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
