import React, { useEffect, useRef, useState } from 'react';

const Bandeau3 = ({bandeau3Ref}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = ['./img/pub4.png', './img/pub5.png']; // Ajoutez les chemins de vos images

  useEffect(() => {
    const interval = setInterval(() => {
      // Changer l'index de l'image toutes les 3 secondes
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, [currentImageIndex, images.length]);

 
    return (
        <div>
        <div className="sectionnewsletter" id='section_formations_ouvertes'>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
              <div className="imagepub">
                  <img src={images[currentImageIndex]} alt="" />
                  <div className="icon-container">
                  <img src="./img/grade.svg" alt="" srcset="" className='image' />
                  </div>
              </div>
              </div>
              <div className="col-lg-6 d-flex flex-column justify-content-center align-items-start py-4">
                <span className='inscris-toi'>Inscris-toi à notre newsletter</span>
                <div className='pt-4'>
                  <form action="">
                    <div className="row">
                      <div className="col-lg-10 mb-4">
                        <input type="text" placeholder='Nom et Prenom' className='form-control input-control' />
                      </div>
                      <div className="col-lg-5 mb-4">
                        <input type="text" placeholder='Email' className='form-control input-control' />
                      </div>
                      <div className="col-lg-5 mb-4">
                        <input type="text" placeholder='Téléphone' className='form-control input-control' />
                      </div>
                      {/* <div className="col-lg-5 mb-4">
                        <select className="form-control input-control">
                          <option value="" disabled selected>Choisir un cours</option>
                          <option value="option1">Option 1</option>
                          <option value="option2">Option 2</option>
                          <option value="option3">Option 3</option>
                        </select>
                      </div> */}
                      <div className="col-lg-10 mt-3">
                        <button type="submit" className="btn btn-block envoyer">Envoyer</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Bandeau3;