import React, { useRef } from 'react';
import Inscription from './Inscription';

const Bandeau1 = () => {
 

   

    return (
        <div>
            <div className="section2" id="accueil">
        <div className="container">
          <h2 className="text2">
            NEOLEAN ACADEMY
          </h2>
          <h6 className="text1">LA PREMIÈRE ÉCOLE AFRICAINE 100% EN LIGNE</h6>
          <div className='d-flex justify-content-center align-items-center mt-5'>
          <Inscription/>

          </div>
          {/* <a href="#section_2" className="btnformation mt-4">Nos Formations</a> */}
        </div>
      </div>
        </div>



    );
};

export default Bandeau1;