import React, { useEffect, useState } from 'react';
import Barhaut from './Barhaut';
import Footer from './Footer';
import { useParams } from 'react-router-dom';
import WhatsAppWidget from './WhatsAppWidget';

const FormationDetailsPlus = ({ listeformations }) => {
    useEffect(() => {
        // Redirigez l'utilisateur vers le haut de la page lorsque le composant est monté
        window.scrollTo(0, 0);
      }, []); 
    const { id, formation: itemProfil, formationId: itemFormationId } = useParams();
    const categoryId = parseInt(id);
    const formationIdInt = parseInt(itemFormationId);

    const [isDisabled, setIsDisable]= useState(false);
  
    // Trouver la catégorie correspondant à l'ID
    const categorie = listeformations.find((cat) => cat.id === categoryId);
  
    if (!categorie) {
      return <div>Catégorie non trouvée</div>;
    }
  
    // Trouver la formation correspondant à l'ID dans la catégorie
    const selectedFormation = categorie.formations.find((form) => form.id === formationIdInt);
  
    
    if (!selectedFormation) {
      return <div>Formation non trouvée</div>;
    }
    

     // Fonction pour gérer le téléchargement de la plaquette
     const handleDownloadClick = () => {

        const downloadUrl = selectedFormation.downloadUrl;
        const filename = selectedFormation.filename;        
       
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;
        // link.setAttribute('download', 'teste1.jpeg'); // Nom du fichier à télécharger
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    return (
        <div className="sectionformationdetailsplus">
            <Barhaut/>
            <div id="top"></div>
            <div className='pb-4'>
            <div className="entete" id="accueil">
                <div className="container">
                <h2 className="text2">{itemProfil}</h2>
                <br />
                <h6 className="text1">{selectedFormation.texte}</h6>
                <div><img className='decor' src={selectedFormation.decor} alt="" /></div>
                <div><button className='btntelecharger text-center' onClick={handleDownloadClick}  >Télécharger la plaquette</button></div>
                </div>
            </div>
            </div>
            <div className="container">
                <div className="row d-flex justify-content-around pt-5">
                    <div className="col-lg-7">
                    <h2 className='titre'>{selectedFormation.niveau} en {selectedFormation.option}</h2>
                    <div className='description py-4'>{selectedFormation.description}</div>
                    <h2 className='titre pb-4'>Objectifs pédagogiques</h2>
                    <div>
                    <ol style={{ textAlign: 'justify' }}>
                        {selectedFormation.objectifs.map((objectif, index) => (
                            <li key={index} style={{ whiteSpace: 'pre-wrap' }}>
                            {index === 0 ? (
                                <span className="first-objectif-style" dangerouslySetInnerHTML={{ __html: objectif }} />
                            ) : index === 1 ? (
                                <span className="second-objectif-style" dangerouslySetInnerHTML={{ __html: objectif }} />
                            ) :index ===2 ? (
                                <span className="second-objectif-style" dangerouslySetInnerHTML={{ __html: objectif }} />
                            ) :index << 3 ? (
                                <span className="second-objectif-style" dangerouslySetInnerHTML={{ __html: objectif }} />
                            ): (
                                objectif
                            )}
                            </li>
                        ))}
                        </ol>
                    </div>
                    

                    </div>
                    <div className="col-lg-4">
                        <div className='blocprogramme px-3 py-4'>
                            <h2 className='programme'>Programme pédagogique :</h2>
                            <ol>
                            {
                                    selectedFormation.programmes.map((programme, index) => (
                                        <>
                                        <li key={index} className='title'> {programme.title} </li>
                                        <ul>
                                            {programme.content.map((item, index) => (
                                            <li className='listeprogramme' key={index}>{item} </li>

                                            ))}
                                        </ul>
                                        </>
                                ))
                            }
                                    </ol>
                            {/* <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit eum provident harum fuga, recusandae dicta itaque aspernatur temporibus reprehenderit! Deleniti eum voluptate accusamus error aliquam quisquam officiis, quis voluptatibus, animi deserunt aut. Id adipisci sit vero porro, veritatis architecto quae veniam praesentium debitis, dignissimos quos corporis facilis ut minus tempora nam delectus? Architecto, explicabo. Porro consequatur quisquam eum, sed dolore et molestias tenetur voluptatibus cupiditate sint qui praesentium atque repellendus dicta, beatae quos accusamus, magni voluptates assumenda ipsum! Odit, necessitatibus fuga hic harum qui eveniet cupiditate iure eos sequi? Ratione deleniti eius optio delectus. Odio et, consectetur autem rerum reprehenderit fugit corporis sed vitae distinctio eligendi veniam, tempora laborum dolores dolor magni reiciendis quisquam omnis perferendis fuga hic fugiat aperiam quam. Officia et, nulla cupiditate distinctio voluptates obcaecati expedita eum velit nesciunt nemo ipsa! Alias eveniet ab vitae nisi et. Modi ipsum accusamus quae est alias! Dolor earum perferendis corrupti.</span> */}
                        </div>
                       
                    </div>
                </div>
                <div className="row d-flex justify-content-around align-items-center py-5">
                    <div className="col-12 col-md-6 col-xl-7">
                    <div className='blocresume'>
                        <span>{selectedFormation.resume}</span>
                    </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                    <div className="blocdebouche">
                            <img src="/icones/iconedebouche.svg" alt="" className='imagedebouche'/>
                            <div>
                            <ul>
                                {selectedFormation.debouches.map((debouche, index) => (
                                    debouche && <li key={index}>{debouche}</li>
                                ))}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mb-5">
                <div className="row py-5 sectiondownload1 d-flex align-items-center justify-content-center">
                    <div className="col-lg-10">
                    <div className='sectiondownload2 py-5'>
                    <div className='bloc'>
                    <span className='accroche d-block text-center mx-auto'>
                    {selectedFormation.accroche}
                    </span>
                        <h2 className='domaine text-center'>{itemProfil}</h2>
                    </div>
                        <div className="text-center">
                        <button className='btntelecharger mt-3' onClick={handleDownloadClick}>Télécharger la plaquette</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            </div>
            <Footer/>
            <WhatsAppWidget/>
        </div>
    );
};

export default FormationDetailsPlus;