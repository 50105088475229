import React, { useEffect, useState } from 'react';

const AutoCounter = ({ maxValue, label1, label2 }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const startAutoCounter = () => {
      const interval = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter < maxValue) {
            return prevCounter + 1;
          } else {
            clearInterval(interval);
            return prevCounter;
          }
        });
      }, 1);
    };

    // const handleScroll = () => {
    //   const section = document.getElementById('section_objectifs');

    //   // Vérifier si l'élément est trouvé
    //   if (section) {
    //     const sectionOffset = section.offsetTop;
    //     const scrollPosition = window.scrollY;

    //     if (scrollPosition >= sectionOffset) {
    //       startAutoCounter();
    //       window.removeEventListener('scroll', handleScroll);
    //     }
    //   }
    // };
    const handleScroll = () => {
        const section = document.getElementById('section_objectifs');
      
        if (section) {
          const sectionOffset = section.offsetTop;
          const scrollPosition = window.scrollY;
          const windowHeight = window.innerHeight;
      
          if (scrollPosition >= sectionOffset - windowHeight / 2) {
            startAutoCounter();
            window.removeEventListener('scroll', handleScroll);
          }
        }
      };
      

    // Ajouter l'écouteur d'événement pour détecter le défilement
    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [maxValue]);

  return (
    <div className="col-lg-3 d-flex flex-column align-items-center">
    <p className='counter d-flex align-items-center justify-content-center'>+{counter}{maxValue !== 700 && maxValue !==15 && '%'}</p>
    <div className='o1'>{label1}</div>
    <div className='o2'>{label2}</div>
  </div>
  );
};

const Objectifs = () => {
  return (
    <div>
      <div className="objectifs" id="section_objectifs">
        <div className="text-center py-5">
          <span className='succes'>Commencez au succès</span><br />
          <span className='h2'>Atteignez vos objectifs avec Neolean Academy</span>
        </div>
        <div className="container pb-5">
          <div className="row">
            <AutoCounter maxValue={1500} label1="étudiants" label2="formés" />
            <AutoCounter maxValue={95} label1="de" label2="réussite" />
            <AutoCounter maxValue={85} label1="d’insertion" label2="à l’emploi" />
            <AutoCounter maxValue={15} label1="partenaires" label2="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Objectifs;
